import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Layout from "../components/layout";
import SectionHead from "../components/newComponents/sectionHead";
import EditLink from "../components/newComponents/editLink";
import SocialMediaButtons from "../components/newComponents/socialShare";
import ImgixFilePreview from "../components/newComponents/ImgixFilePreview";
import { getPrettyDate } from "../utils/dates";

export const query = graphql`
  query NewsLetterEntryQuery($slug: String!) {
    entry: craftNewslettersNewslettersEntry(slug: { eq: $slug }) {
      displayTitle
      summary
      url
      uri
      remoteId
      sectionHandle
      newsletterLink
      postDate
      downloads {
        url
        size
        kind
      }
      featureImage {
        ...AssetFragment
      }
    }

    site: site {
      siteMetadata {
        url
      }
    }
  }
`;

const NewsletterEntry = ({ data: { entry, site }, pageContext }) => {

  const file = entry.downloads.length > 0 ? entry.downloads[0] : undefined;

  return (
    <Layout pageContext={pageContext}>
      <Seo title={entry.title} image={ entry.featureImage[0]?.url} />
      <div className="main">
        <section className="section-inside" style={{ padding: "0" }}>
          <div className="shell">
            <SectionHead
              heading={entry.displayTitle}
              image={entry.featureImage[0]}
            />
            <div className="section__inner">
              <aside className="section__aside">

                <ul className="list-info">

                <li>
                    <p className="list__title">
                      <strong>Date</strong>
                    </p>
                    <p className="list__details">
                      {getPrettyDate(entry.postDate)}
                    </p>
                  </li>


                  {file && file.title &&
                  <li>
                    <p className="list__title">
                      <strong>File name</strong>
                    </p>
                    <p className="list__details">
                      {file.title}
                    </p>
                  </li>
                  }

                {file && file.kind &&

                  <li>
                    <p className="list__title">
                      <strong>File type</strong>
                    </p>
                    <p className="list__details uppercase">
                      {file.kind}
                    </p>
                  </li>
                }             

                  {file && file.url && <li>
                    <p className="list__title">
                      <strong>Preview</strong>
                    </p>
                    <p className="list__details">
                      <div className="pr-2">
                        <ImgixFilePreview fit="fill" fileUrl={file.url} className="mt-2 border-gray-100 border-solid border"  />
                      </div>
                    </p>
                  </li>}
                 
                </ul>             
              </aside>

              <div className="section__content">

                <div dangerouslySetInnerHTML={{ __html: entry.summary }} className="mb-2" />
                {entry.downloads.length > 0 && <a href={entry.downloads[0].url} className="btn btn--green" target="_blank" rel="noreferrer" download>Download</a>}
                {entry.newsletterLink && <a href={entry.newsletterLink} className="btn btn--green" target="_blank" rel="noreferrer">View newsletter</a>}

                {/* {entry.newsletterLink} */}
                {/* <Matrix blocks={entry.bodyContent} /> */}

                <SocialMediaButtons url={`${site.siteMetadata.url}/${entry.uri}`} title={`${entry.title}`} summary={entry.summary} twitterVia="GenesisEconomic" linkedInSource="Genesis Analytics"/>
                <EditLink
                  sectionHandle={entry.sectionHandle}
                  remoteId={entry.remoteId}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default NewsletterEntry
