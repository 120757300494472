import React from "react";
import { getGatsbyImageData } from '@imgix/gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ImgixFilePreview = ({ fileUrl, fit = 'crop', className, width = 500, height, sizes = '(min-width: 500px) 350px, 560px', ar = 16/9 }) => {

  const imgixParams = {
    // imgix parameters
    width: width, // required
    bg: 'FFF', // don't want PDF transparency - apply white background
    auto: ["format", "compress"], // recommended for all images
    fit: fit,
    'fp-x': 0.5,
    'fp-y': 0, 
    'fp-z': 1
  }

  if(height) imgixParams["height"] = height;

  if (fit==="fill") {
    imgixParams["fill"] = 'solid';
    imgixParams["fill-color"] = 'FFF';
  }

  const gatsbyImageData = getGatsbyImageData({
    src: fileUrl,
    imgixParams: imgixParams,
    layout: "constrained",
    aspectRatio: ar,
    sizes: sizes,
  });

  return <GatsbyImage className={className} image={gatsbyImageData} alt=""/>;
}

export default ImgixFilePreview;